<template lang="pug">
include ../pug/svg
section#profile
	Form(v-if="user" ref="profile" :validation-schema="profileValidation" v-slot="{ handleReset, errors, isSubmitting, values, meta }" @submit="updateProfile")
		fieldset
			legend Contact Details
			label.hlf
				Field(name="firstName" type="text" placeholder="Required" autocomplete="off" :value="customer?.firstName" :class="{err:errors.firstName}" :validateOnInput="true")
				span First Name
				transition(name="fade")
					ErrorMessage(name="firstName" as="em")
			label.hlf
				Field(name="lastName" type="text" placeholder="Required" autocomplete="off" :value="customer?.lastName" :class="{err:errors.lastName}" :validateOnInput="true")
				span Last Name
				transition(name="fade")
					ErrorMessage(name="lastName" as="em")
			label.hlf
				Field(name="email" type="text" placeholder="Required" autocomplete="off" :value="user.email" :class="{err:errors.email}" :validateOnInput="true")
				span Email
				transition(name="fade")
					ErrorMessage(name="email" as="em")
			label.hlf
				Field(name="phone" type="text" placeholder="Required" autocomplete="off" :value="customer?.shipping.phone" :class="{err:errors.email}" :validateOnInput="true")
				span Phone
				transition(name="fade")
					ErrorMessage(name="phone" as="em")
		fieldset
			legend Delivery Address
			label.toggle
				Field(name="business" type="checkbox" class="aorb" :value="true" :uncheckedValue="false" v-model="business")
				span(:class="{unchecked:values.business}") Individual
				span(:class="{unchecked:!values.business}") Business
			label(v-if="values.business")
				Field(name="businessName" type="text" placeholder="Required" autocomplete="off" :value="customer?.businessName" :class="{err:errors.businessName}" :validateOnInput="true")
				span Business Name
				transition(name="fade")
					ErrorMessage(name="firstName" as="em")
			label
				Field(name="address1" type="text" placeholder="Required" autocomplete="off" :value="address?.line1" :class="{err:errors.address1}" :validateOnInput="true")
				span Address
				transition(name="fade")
					ErrorMessage(name="address1" as="em")
			label
				Field(name="address2" type="text" placeholder="Optional" autocomplete="off" :value="address?.line2" :class="{err:errors.address2}" :validateOnInput="true")
				span Address
				transition(name="fade")
					ErrorMessage(name="address2" as="em")
			label.hlf
				Field(name="city" type="text" placeholder="Required" autocomplete="off" :value="address?.city" :class="{err:errors.city}" :validateOnInput="true")
				span Town
				transition(name="fade")
					ErrorMessage(name="city" as="em")
			label.hlf
				Field(name="state" type="text" placeholder="Optional" autocomplete="off" :value="address?.state" :class="{err:errors.state}" :validateOnInput="true")
				span County
				transition(name="fade")
					ErrorMessage(name="city" as="em")
			label.hlf
				Field(name="postalCode" type="text" placeholder="Required" autocomplete="off" :value="address?.postal_code" :class="{err:errors.postalCode}" :validateOnInput="true")
				span Postcode
				transition(name="fade")
					ErrorMessage(name="postalCode" as="em")
			label.hlf
				Field(v-slot="{value}" name="shipCountry" as="select" placeholder="Required" autocomplete="off" :class="{err:errors.shipCountry,empty:!values.shipCountry}" v-model="shipCountry")
					option(value="")
					option(v-for="(country, index) in pandp.countries" :value="country.code" :selected="value&&value===country.code") {{country.country}}		
				span Country
				+svg(svg-filename="iconArrow")
				transition(name="fade")
					ErrorMessage(name="shipCountry" as="em")
		fieldset
			legend Account Settings
			label.hlf
				Field(name="roleId" as="select" placeholder="Required" autocomplete="off" :value="user.roleId" :class="{err:errors.role}" disabled)
					//-option(value="2") Artist
					//-option(value="3") Label
					option(value="2") Customer
					option(value="4" v-if="user.roleId===4") Owner
				span Account Type
				+svg(svg-filename="iconArrow")
				transition(name="fade")
					ErrorMessage(name="city" as="em")
			label.hlf
				Field#subscribe(name="subscribe" type="checkbox" :value="1" :uncheckedValue="0" v-model="subscribed")
				span Keep me up to date on new releases and offers
			label.hlf(v-if="!values.changePassword")
				input(type="password" value="********" disabled)
				span Password
			label.hlf(v-else)
				Field(name="password" type="text" placeholder="Required" autocomplete="off" :class="{err:errors.password}" :validateOnInput="true")
				span(v-if="!values.changePassword") Password
				span(v-else) New Password
				transition(name="fade")
					ErrorMessage(name="password" as="em")
			label.hlf
				Field(name="changePassword" type="checkbox" :value="true" :uncheckedValue="false" v-model="changePassword")
				span Change account password
		fieldset.ctrl
			button.text.solid(type="submit" :disabled="!meta.dirty||!meta.valid||isSubmitting") Save
			button.text.outline(type="button" :disabled="!meta.dirty||!meta.valid||isSubmitting" @click="handleReset") Cancel
</template>

<script>
import PaymentService from '../services/PaymentService';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(Yup); // extend yup

export default {
	name: 'AccountProfile',
	components: {
		Form,
		Field,
		ErrorMessage,
	},
	data() {
		const profileValidation = Yup.object().shape({
			firstName: Yup.string().label('First Name').required(),
			lastName: Yup.string().label('Last Name').required(),
			businessName: Yup.string().label('Business Name').when('business', {
				is: true,
				then: Yup.string().required()
			}),			
			email: Yup.string().label('Email').email().required(),
			phone: Yup.string().label('Phone').required(),			
			password: Yup.string().label('Password').when('changePassword', {
				is: true,
				then: Yup.string().password().required()
			}),			
			address1: Yup.string().label('Address').required(),
			address2: Yup.string(),
			city: Yup.string().label('Town').required(),
			state: Yup.string().label('County'),
			postalCode: Yup.string().label('Postcode').required(),
			shipCountry: Yup.string().label('Country').required(),
		});
		
		return {
			profileValidation,
			pandp: [],
			changePassword: false,
		}
	},
	async created() {
		const {data} = await PaymentService.getDelivery();
//		this.shipCountry = 'GB';
		this.pandp = data;
	},
	computed: {
		user() {
			return this.$store.state.auth.user;
		},
		customer() {
			return this.user.customer;
		},
		address() {
			return this.customer?.shipping.address;
		},
		shipCountry: {
			get() {
//				return this.address?.country||'GB';
				return this.address?.country||'';
			},
			set() {} // prevent read only warning
		},
		business: {
			get() {
				return (this.customer?.businessName) ? true : false;
			},
			set() {
			}
		},
		subscribed: {
			get() {
				return (this.user.subscribe) ? 1 : 0;
			},
			set() {}
		},
	},
	methods: {
		async updateProfile(formData, { resetForm }) {
			// remove previous business name if business unchecked
			formData.businessName = (formData.business) ? formData.businessName : null;
			
			const data = {
				email: formData.email,
				password: (formData.changePassword) ? formData.password : null,
				subscribe: formData.subscribe,
				firstName: formData.firstName,
				lastName: formData.lastName,
				businessName: formData.businessName,
				shipName: formData.firstName + ' ' + formData.lastName,
				shipAddress1: formData.address1,
				shipAddress2: formData.address2 || null,
				shipTown: formData.city,
				shipCounty: formData.state || null,
				shipPostcode: formData.postalCode,
				shipCountry: formData.shipCountry,
				shipPhone: formData.phone,
			};
			
			if (!formData.business) {
				// remove business name as yup validation fails against null value
				delete formData.businessName;
			}
			
			// reset form meta
			await resetForm({
				values: formData, // resupply form data to avoid reverting to original form values
			});
			
			// update db/store			
			await this.$store.dispatch('auth/updateProfile', data);
		},
	},
};
</script>

<style lang="scss">
#profile {
	form {
		margin: auto;
		max-width: 900px;
		padding: 40px 40px 20px 40px;
		border-radius: 4px;
		background: #FFF;
		font-size: 1.4rem;		
		box-shadow: 0 2px 10px rgba(#000, .2);
	}
	h2 {
		margin-bottom: 40px;
		text-align: center;
	}
	label {
		span {
			font-size: 1.4rem;
		}
	}
	label.toggle {
		width: auto;
		margin: auto;
		margin-bottom: 20px;
	}
	fieldset {
		margin-bottom: 20px;
		&.ctrl {
			justify-content: center;
			button {
				margin: 0 10px;
			}
		}
	}
	legend {
		font-size: 1.4rem;
		margin-bottom: 20px;
		font-family: 'SansBold';
		text-align: center;
		text-transform: uppercase;
		letter-spacing: .2em;
	}
	@media only screen and (max-width: 540px) {
		form {
			padding: 40px 20px 20px 20px;
		}
	}
	@media only screen and (max-width: 767px) {
		label {
			&.hlf {
				width: 100%;
			}
		}
		[type="checkbox"] + span {
			margin-bottom: 10px;
		}
	}
}
</style>